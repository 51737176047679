define("discourse/plugins/discourse-encrypt/discourse/connectors/user-preferences-security/encrypt", ["exports", "@ember/object", "discourse/plugins/discourse-encrypt/lib/database", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/pack", "discourse/plugins/discourse-encrypt/lib/protocol", "I18n", "@ember/application", "discourse-common/config/environment", "discourse/lib/ajax-error", "discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key", "discourse/plugins/discourse-encrypt/discourse/components/modal/export-key-pair", "discourse/plugins/discourse-encrypt/discourse/components/modal/manage-paper-keys", "discourse/plugins/discourse-encrypt/discourse/components/modal/reset-key-pair", "discourse/plugins/discourse-encrypt/discourse/components/modal/rotate-key-pair"], function (_exports, _object, _database, _discourse, _pack, _protocol, _I18n, _application, _environment, _ajaxError, _generatePaperKey, _exportKeyPair, _managePaperKeys, _resetKeyPair, _rotateKeyPair) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = (_obj = {
    setupComponent(args, component) {
      component.set("isInsecureContext", !window.isSecureContext);

      // Whether plugin is enabled for current user
      (0, _object.defineProperty)(component, "canEnableEncrypt", (0, _object.computed)("model.can_encrypt", () => this.model.can_encrypt));

      // Only current user can enable encryption for themselves
      (0, _object.defineProperty)(component, "isCurrentUser", (0, _object.computed)("currentUser.id", "model.id", () => this.currentUser.id === this.model.id));
      if (component.isCurrentUser) {
        component.setProperties({
          encryptStatus: (0, _discourse.getEncryptionStatus)(args.model),
          /** Value of passphrase input. */
          passphrase: "",
          /** Whether it is an import operation. */
          importIdentity: false,
          /** Key to be imported .*/
          identity: "",
          identityPlaceholder: (0, _pack.getPackedPlaceholder)(),
          /** Whether any operation (AJAX request, key generation, etc) is
           *  in progress.
           */
          inProgress: false,
          listener() {
            component.set("encryptStatus", (0, _discourse.getEncryptionStatus)(args.model));
          },
          didInsertElement() {
            this._super(...arguments);
            this.appEvents.on("encrypt:status-changed", this, this.listener);
          },
          willDestroyElement() {
            this._super(...arguments);
            this.appEvents.off("encrypt:status-changed", this, this.listener);
          }
        });
        (0, _object.defineProperty)(component, "isEncryptEnabled", (0, _object.computed)("encryptStatus", () => this.encryptStatus !== _discourse.ENCRYPT_DISABLED));
        (0, _object.defineProperty)(component, "isEncryptActive", (0, _object.computed)("encryptStatus", () => this.encryptStatus === _discourse.ENCRYPT_ACTIVE));
      } else {
        (0, _object.defineProperty)(component, "isEncryptEnabled", (0, _object.computed)("model.encrypt_public", () => !!this.model.encrypt_public));
      }
    },
    enableEncrypt() {
      this.set("inProgress", true);
      const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
      return (0, _discourse.enableEncrypt)(this.model, this.importIdentity && this.identity).then(() => {
        this.appEvents.trigger("encrypt:status-changed");
      }).catch(() => dialog.alert(_I18n.default.t("encrypt.preferences.key_pair_invalid"))).finally(() => {
        this.setProperties({
          passphrase: "",
          inProgress: false,
          importIdentity: false,
          identity: ""
        });
      });
    },
    activateEncrypt() {
      this.set("inProgress", true);
      const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
      const identityPromise = this.importIdentity ? (0, _protocol.importIdentity)((0, _pack.unpackIdentity)(this.identity)).then(identity => (0, _database.saveDbIdentity)(identity)) : (0, _discourse.activateEncrypt)(this.model, this.passphrase);
      return identityPromise.then(() => {
        this.appEvents.trigger("encrypt:status-changed");
      }).catch(() => {
        if (this.importIdentity) {
          dialog.alert(_I18n.default.t("encrypt.preferences.key_pair_invalid"));
        } else {
          dialog.alert(_I18n.default.t("encrypt.preferences.paper_key_invalid"));
        }
      }).finally(() => this.setProperties({
        passphrase: "",
        inProgress: false,
        importIdentity: false,
        identity: ""
      }));
    },
    deactivateEncrypt() {
      this.setProperties("inProgress", true);
      (0, _database.deleteDb)().then(() => {
        this.appEvents.trigger("encrypt:status-changed");
      }).finally(() => this.set("inProgress", false));
    },
    generatePaperKey(device) {
      const modal = (0, _application.getOwner)(this).lookup("service:modal");
      modal.show(_generatePaperKey.default, {
        model: {
          device
        }
      });
    },
    savePreference() {
      this.set("saved", false);
      return this.model.save(["encrypt_pms_default"]).then(() => {
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          window.location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    selectEncryptPreferencesDropdownAction(actionId) {
      const modal = (0, _application.getOwner)(this).lookup("service:modal");
      switch (actionId) {
        case "export":
          modal.show(_exportKeyPair.default);
          break;
        case "managePaperKeys":
          modal.show(_managePaperKeys.default, {
            model: {
              user: this.model
            }
          });
          break;
        case "rotate":
          modal.show(_rotateKeyPair.default);
          break;
        case "reset":
          modal.show(_resetKeyPair.default, {
            model: {
              user: this.model
            }
          });
          break;
      }
    },
    selectEncryptEnableDropdownAction(actionId) {
      const modal = (0, _application.getOwner)(this).lookup("service:modal");
      switch (actionId) {
        case "import":
          this.toggleProperty("importIdentity");
          break;
        case "reset":
          modal.show(_resetKeyPair.default, {
            model: {
              user: this.model
            }
          });
          break;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "enableEncrypt", [_object.action], Object.getOwnPropertyDescriptor(_obj, "enableEncrypt"), _obj), _applyDecoratedDescriptor(_obj, "activateEncrypt", [_object.action], Object.getOwnPropertyDescriptor(_obj, "activateEncrypt"), _obj), _applyDecoratedDescriptor(_obj, "deactivateEncrypt", [_object.action], Object.getOwnPropertyDescriptor(_obj, "deactivateEncrypt"), _obj), _applyDecoratedDescriptor(_obj, "generatePaperKey", [_object.action], Object.getOwnPropertyDescriptor(_obj, "generatePaperKey"), _obj), _applyDecoratedDescriptor(_obj, "savePreference", [_object.action], Object.getOwnPropertyDescriptor(_obj, "savePreference"), _obj), _applyDecoratedDescriptor(_obj, "selectEncryptPreferencesDropdownAction", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectEncryptPreferencesDropdownAction"), _obj), _applyDecoratedDescriptor(_obj, "selectEncryptEnableDropdownAction", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectEncryptEnableDropdownAction"), _obj)), _obj);
});