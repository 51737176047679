define("discourse/plugins/discourse-encrypt/discourse/initializers/add-search-results", ["exports", "discourse-common/lib/icon-library", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/models/post", "discourse/models/topic", "discourse/plugins/discourse-encrypt/lib/discourse", "I18n", "rsvp", "discourse-common/config/environment"], function (_exports, _iconLibrary, _ajax, _pluginApi, _post, _topic, _discourse, _I18n, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CACHE_KEY = "discourse-encrypt-cache";
  function addObjectToCache(cache, type, object) {
    if (!cache[type]) {
      cache[type] = {};
    }
    cache[type][object.id] = object;
  }
  function addPostToCache(cache, post) {
    post.topic_title_headline = null;
    addObjectToCache(cache, "posts", post);
  }
  function addTopicToCache(cache, topic) {
    if (!topic.topic_key || !topic.encrypted_title) {
      return;
    }
    (0, _discourse.putTopicKey)(topic.id, topic.topic_key);
    (0, _discourse.putTopicTitle)(topic.id, topic.encrypted_title);
    return (0, _discourse.getTopicTitle)(topic.id).then(title => {
      topic.title = title;
      topic.fancy_title = `${(0, _iconLibrary.iconHTML)("user-secret")} ${title}`;
      topic.excerpt = null;
      addObjectToCache(cache, "topics", topic);
    }).catch(() => {});
  }
  function getCache(session, filterKey) {
    let key = CACHE_KEY;
    if (filterKey) {
      key += `-${filterKey.replaceAll(".", "-")}`;
    }
    let cache;
    if (!(0, _environment.isTesting)()) {
      cache = session.get(key);
    }
    if (!cache) {
      session.set(key, cache = {});
    }
    return cache;
  }
  function loadCache(cache, term) {
    return (0, _ajax.ajax)("/encrypt/posts", {
      data: {
        term
      }
    }).then(result => {
      const promises = [];
      result.posts?.forEach(post => addPostToCache(cache, post));
      result.topics?.forEach(topic => promises.push(addTopicToCache(cache, topic)));
      return _rsvp.Promise.all(promises);
    });
  }
  function addEncryptedSearchResultsFromCache(cache, results) {
    const terms = results.grouped_search_result.term.toLowerCase().trim().split(/\s+/).filter(term => !term.startsWith("@") && !term.includes(":"));

    // Add to results encrypted topics that have matching titles
    const existentTopicIds = new Set(results.topics.map(topic => topic.id));
    const topics = {};
    Object.values(cache.topics || {}).forEach(topic => {
      if (existentTopicIds.has(topic.id)) {
        return;
      }
      if (!topic.title) {
        // eslint-disable-next-line no-console
        console.warn("Encrypted topic title is missing: topic =", topic);
        return;
      }
      if (terms.every(term => topic.title.toLowerCase().includes(term))) {
        topics[topic.id] = topic = _topic.default.create(topic);
        results.topics.unshift(topic);
      }
    });

    // Add associated posts for each new topic
    Object.values(cache.posts || {}).forEach(post => {
      if (post.post_number !== 1 || !topics[post.topic_id]) {
        return;
      }
      post = _post.default.create(post);
      post.setProperties({
        topic: topics[post.topic_id],
        blurb: _I18n.default.t("encrypt.encrypted_post")
      });
      results.posts.unshift(post);
      results.grouped_search_result.post_ids.unshift(post.id);
    });

    // Reset topic_title_headline for encrypted results
    if (cache.topics) {
      results.posts.map(post => {
        if (cache.topics[post.topic_id]) {
          post.set("topic_title_headline", "");
        }
      });
    }
  }
  var _default = _exports.default = {
    name: "add-search-results",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }
      const session = container.lookup("service:session");
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.addSearchResultsCallback(results => {
          const promises = [];
          const groupedResult = results?.grouped_search_result;
          const term = groupedResult?.term || "";
          const filters = term.toLowerCase().trim().split(/\s+/).filter(t => /^(@|after:|before:).+$/i.test(t));
          const cache = getCache(session, filters.join("-"));

          // Decrypt existing topics and cache them
          results.topics.forEach(topic => {
            promises.push(addTopicToCache(cache, topic));
          });

          // Search for more encrypted topics
          if (groupedResult && groupedResult.type_filter === "private_messages" && !term.split(" ").some(t => /^group_messages:(.+)$/i.test(t))) {
            let cachePromise = _rsvp.Promise.resolve();
            if (!cache.loaded) {
              cachePromise = loadCache(cache, filters.join(" "));
              cache.loaded = true;
            }
            promises.push(cachePromise.then(() => addEncryptedSearchResultsFromCache(cache, results)));
          }
          return _rsvp.Promise.all(promises).then(() => results);
        });
      });
    }
  };
});