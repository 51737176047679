define("discourse/plugins/discourse-encrypt/lib/debounced-queue", ["exports", "discourse-common/lib/debounce", "rsvp"], function (_exports, _debounce, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DebouncedQueue {
    constructor(wait, handler) {
      this.wait = wait;
      this.handler = handler;
      this.queue = null;
      this.promise = null;
      this.resolve = null;
      this.reject = null;
    }
    push() {
      if (!this.queue) {
        this.queue = [];
        this.promise = new _rsvp.Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
        (0, _debounce.default)(this, this.pop, this.wait);
      }
      this.queue.push(...arguments);
      return this.promise;
    }
    pop() {
      const items = Array.from(new Set(this.queue));
      this.handler(items).then(this.resolve).catch(this.reject);
      this.queue = null;
      this.promise = null;
      this.resolve = null;
    }
  }
  _exports.default = DebouncedQueue;
});