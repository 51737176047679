define("discourse/plugins/discourse-encrypt/discourse/initializers/decrypt-topics", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "discourse-common/lib/debounce", "discourse-common/lib/icon-library", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/lib/utilities", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse-common/utils/decorators"], function (_exports, _component, _object, _runloop, _debounce, _iconLibrary, _pluginApi, _text, _utilities, _discourse, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "discourse-encrypt";

  /**
   * Decrypts elements that contain topic titles
   *
   * @param {String} containerSelector Item list (container) selector
   * @param {String} elementSelector   Encrypted title element selector
   *                                   If not present, the container is used
   * @param {Boolean} addIcon          Adds "user-secret" icon before title
   */
  function decryptTopicTitles(containerSelector, elementSelector) {
    let addIcon = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    document.querySelectorAll(containerSelector).forEach(element => {
      const titleElement = elementSelector ? element.querySelector(elementSelector) : element;
      if (!titleElement) {
        return;
      }
      const topicId = element.dataset.topicId || titleElement.dataset.topicId;
      if (!topicId || !(0, _discourse.hasTopicTitle)(topicId)) {
        return;
      }
      (0, _discourse.getTopicTitle)(topicId).then(title => {
        title = (0, _text.emojiUnescape)((0, _utilities.escapeExpression)(title));
        if (addIcon) {
          const icon = (0, _iconLibrary.iconHTML)("user-secret", {
            title: "encrypt.encrypted_icon_title",
            class: "private-message-glyph"
          });
          titleElement.innerHTML = icon + " " + title;
        } else {
          titleElement.innerHTML = title;
        }
      }).catch(() => {});
    });
  }

  /**
   * Replaces PM icon with "user-secret" icon
   *
   * @param {String} containerSelector Item list (container) selector
   * @param {String} elementSelector   Encrypted title element selector
   *                                   If not present, the container is used
   * @param {Boolean} iconSelector     Icon container selector
   */
  function replaceIcons(containerSelector, elementSelector, iconSelector) {
    document.querySelectorAll(containerSelector).forEach(element => {
      const titleElement = elementSelector ? element.querySelector(elementSelector) : element;
      if (!titleElement) {
        return;
      }
      const topicId = element.dataset.topicId || titleElement.dataset.topicId;
      if (!topicId || !(0, _discourse.hasTopicTitle)(topicId)) {
        return;
      }
      const iconElement = element.querySelector(iconSelector);
      if (iconElement) {
        iconElement.innerHTML = (0, _iconLibrary.iconHTML)("user-secret", {
          title: "encrypt.encrypted_icon_title",
          class: "private-message-glyph"
        });
      }
    });
  }
  let registeredComponentHook = false;
  var _default = _exports.default = {
    name: "decrypt-topics",
    container: null,
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }

      // Save a reference to container to be used by `decryptTopicPage`
      this.container = container;
      const appEvents = container.lookup("service:app-events");
      appEvents.on("encrypt:status-changed", this, this.decryptTopicTitles);
      appEvents.on("page:changed", this, this.decryptTopicPage);
      const self = this;
      if (!registeredComponentHook) {
        // Try to decrypt new titles that may appear after rendering a component
        _object.default.reopen.call(_component.default, {
          didRender() {
            (0, _runloop.scheduleOnce)("afterRender", self, () => {
              (0, _debounce.default)(self, self.decryptTopicTitles, 500);
            });
            return this._super(...arguments);
          }
        });
        registeredComponentHook = true;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        var _dec, _obj;
        // Full-screen notification list topic titles
        api.reopenWidget("default-notification-item", {
          description() {
            if (this.attrs.fancy_title && this.attrs.topic_id && this.attrs.topic_key) {
              const decrypted = (0, _discourse.syncGetTopicTitle)(this.attrs.topic_id);
              if (decrypted) {
                return `<span data-topic-id="${this.attrs.topic_id}">${(0, _text.emojiUnescape)((0, _utilities.escapeExpression)(decrypted))}</span>`;
              }
            }
            return this._super(...arguments);
          }
        });
        if (api.registerModelTransformer) {
          api.registerModelTransformer("topic", async topics => {
            for (const topic of topics) {
              if (topic.topic_key && topic.encrypted_title) {
                (0, _discourse.putTopicKey)(topic.id, topic.topic_key);
                (0, _discourse.putTopicTitle)(topic.id, topic.encrypted_title);
                try {
                  const decryptedTitle = await (0, _discourse.getTopicTitle)(topic.id);
                  if (decryptedTitle) {
                    topic.fancy_title = (0, _utilities.escapeExpression)(decryptedTitle);
                  }
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.warn(`Decrypting the title of encrypted message (topicId: ${topic.id}) failed with the following error:`, err, err.stack);
                }
              }
            }
          });
          api.registerModelTransformer("bookmark", async bookmarks => {
            for (const bookmark of bookmarks) {
              if (bookmark.topic_id && bookmark.topic_key && bookmark.encrypted_title) {
                (0, _discourse.putTopicKey)(bookmark.topic_id, bookmark.topic_key);
                (0, _discourse.putTopicTitle)(bookmark.topic_id, bookmark.encrypted_title);
                try {
                  const decryptedTitle = await (0, _discourse.getTopicTitle)(bookmark.topic_id);
                  if (decryptedTitle) {
                    bookmark.title = decryptedTitle;
                  }
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.warn(`Decrypting the title of encrypted message (topicId: ${bookmark.topic_id}) failed with the following error:`, err, err.stack);
                }
              }
            }
          });
          api.registerModelTransformer("notification", async notifications => {
            for (const notification of notifications) {
              if (notification.topic_id && notification.topic_key && notification.encrypted_title) {
                (0, _discourse.putTopicKey)(notification.topic_id, notification.topic_key);
                (0, _discourse.putTopicTitle)(notification.topic_id, notification.encrypted_title);
                try {
                  const decryptedTitle = await (0, _discourse.getTopicTitle)(notification.topic_id);
                  if (decryptedTitle) {
                    notification.fancy_title = (0, _utilities.escapeExpression)(decryptedTitle);
                  }
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.warn(`Decrypting the title of encrypted message (topicId: ${notification.topic_id}) failed with the following error:`, err, err.stack);
                }
              }
            }
          });
        }
        api.decorateWidget("header:after", helper => {
          if (helper.widget.state.userVisible || helper.widget.state.searchVisible) {
            (0, _debounce.default)(self, self.decryptTopicTitles, 500);
          }
        });
        api.modifyClass("controller:topic", (_dec = (0, _decorators.observes)("editingTopic"), (_obj = {
          pluginId: PLUGIN_ID,
          _editingTopicChanged() {
            if (this.get("editingTopic")) {
              const topicId = this.get("model.id");
              (0, _discourse.getTopicTitle)(topicId).then(topicTitle => {
                // Update the title stored in buffered state
                this.buffered.set("title", topicTitle);
              });
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "_editingTopicChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "_editingTopicChanged"), _obj)), _obj)));
      });
    },
    decryptTopicTitles() {
      // Title in miscellaneous
      decryptTopicTitles("a.raw-topic-link", null, true);
      decryptTopicTitles("a.topic-link", "span");
      decryptTopicTitles("a.topic-link", null, true);

      // Title in site header
      decryptTopicTitles("h1.header-title", ".topic-link");

      // Title in topic lists
      decryptTopicTitles(".topic-list-item, .latest-topic-list-item", ".title", true);

      // Replace PM icons
      replaceIcons("h1", null, ".private-message-glyph-wrapper");
      replaceIcons("h1", ".topic-link", ".private-message-glyph-wrapper");

      // Decrypt topic controller
      // This is necessary because sometimes the model is loaded after
      // page:changed event was triggered.
      if (!this.container || this.container.isDestroyed || this.container.isDestroying) {
        return;
      }
      const {
        currentRouteName
      } = this.container.lookup("service:router");
      this.decryptTopicPage({
        currentRouteName
      });
    },
    decryptTopicPage(data) {
      if (!data.currentRouteName?.startsWith("topic.")) {
        return;
      }
      if (!this.container || this.container.isDestroyed || this.container.isDestroying) {
        return;
      }
      const topicController = this.container.lookup("controller:topic");
      const topic = topicController.get("model");
      const topicId = topic.id;
      if (topic?.encrypted_title) {
        document.querySelector(".private_message").classList.add("encrypted");
      }
      (0, _discourse.getTopicTitle)(topicId).then(topicTitle => {
        // Update fancy title stored in model
        topicController.model.set("fancy_title", (0, _utilities.escapeExpression)(topicTitle));

        // Update document title
        const documentTitle = this.container.lookup("service:document-title");
        documentTitle.setTitle(documentTitle.getTitle().replace(topicController.model.title, topicTitle));
      });
    }
  };
});