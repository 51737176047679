define("discourse/plugins/discourse-encrypt/discourse/connectors/composer-action-after/encrypt", ["exports", "@ember/object", "discourse/plugins/discourse-encrypt/lib/discourse", "I18n"], function (_exports, _object, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const {
        currentUser
      } = component;
      component.setProperties({
        encryptStatus: (0, _discourse.getEncryptionStatus)(currentUser),
        listener() {
          component.set("encryptStatus", (0, _discourse.getEncryptionStatus)(currentUser));
        },
        didInsertElement() {
          this._super(...arguments);
          this.appEvents.on("encrypt:status-changed", this, this.listener);
        },
        willDestroyElement() {
          this._super(...arguments);
          this.appEvents.off("encrypt:status-changed", this, this.listener);
        }
      });
      (0, _object.defineProperty)(component, "isEncryptEnabled", (0, _object.computed)("encryptStatus", () => this.encryptStatus !== _discourse.ENCRYPT_DISABLED));
      (0, _object.defineProperty)(component, "isEncryptActive", (0, _object.computed)("encryptStatus", () => this.encryptStatus === _discourse.ENCRYPT_ACTIVE));

      // Whether the encrypt controls should be displayed or not
      //
      // These are displayed only for new topics or already encrypted topics.
      (0, _object.defineProperty)(component, "showEncryptControls", (0, _object.computed)("model.isNew", "model.creatingPrivateMessage", "model.topic.encrypted_title", () => {
        return this.model.isNew && this.model.creatingPrivateMessage || this.model.topic && this.model.topic.encrypted_title;
      }));

      // Whether the user can encrypt the current message or not.
      //
      // This is true usually when an encrypt error is set:
      //  - the user does not have a key for the current topic
      //  - one of the recipients is a group
      //  - one of the recipients did not enable encrypt
      (0, _object.defineProperty)(component, "canEncrypt", (0, _object.computed)("model.encryptError", () => {
        return !this.model.encryptError;
      }));

      // Whether the user can disable encryption for the current message or not.
      //
      // A user cannot disable encryption when replying to an already encrypted
      // private message.
      (0, _object.defineProperty)(component, "canDisableEncrypt", (0, _object.computed)("model.topic.encrypted_title", () => {
        return !(this.model.topic && this.model.topic.encrypted_title);
      }));

      // Whether the encryption checkbox is disabled or not.
      (0, _object.defineProperty)(component, "disabled", (0, _object.computed)("model.isEncrypted", "canEncrypt", "canDisableEncrypt", () => {
        return this.model.isEncrypted ? !this.canDisableEncrypt : !this.canEncrypt;
      }));
      (0, _object.defineProperty)(component, "title", (0, _object.computed)("model.isEncrypted", "model.encryptError", () => {
        if (this.model.encryptError) {
          return this.model.encryptError;
        } else if (this.model.isEncrypted) {
          return _I18n.default.t("encrypt.checkbox.checked");
        } else {
          return _I18n.default.t("encrypt.checkbox.unchecked");
        }
      }));
    },
    actions: {
      clicked() {
        if (!this.disabled) {
          this.model.setProperties({
            isEncrypted: !this.model.isEncrypted,
            isEncryptedChanged: true,
            showEncryptError: !this.model.isEncrypted,
            deleteAfterMinutes: null,
            deleteAfterMinutesLabel: null
          });
        } else {
          this.model.set("showEncryptError", !this.model.showEncryptError);
        }
      },
      timerClicked(actionId, args) {
        if (actionId) {
          this.model.setProperties({
            deleteAfterMinutes: actionId,
            deleteAfterMinutesLabel: args?.name
          });
        } else {
          this.model.setProperties({
            deleteAfterMinutes: null,
            deleteAfterMinutesLabel: null
          });
        }
      }
    }
  };
});