define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-status", ["exports", "discourse-common/lib/get-url", "discourse/lib/plugin-api", "discourse/plugins/discourse-encrypt/lib/database", "discourse/plugins/discourse-encrypt/lib/discourse", "I18n", "@ember/template", "discourse-common/config/environment"], function (_exports, _getUrl, _pluginApi, _database, _discourse, _I18n, _template, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "encrypt-status",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const appEvents = container.lookup("service:app-events");
      appEvents.on("encrypt:status-changed", skipReload => {
        if (!skipReload && !(0, _environment.isTesting)()) {
          window.location.reload();
        }
      });
      const status = (0, _discourse.getEncryptionStatus)(currentUser);
      if (!currentUser || status !== _discourse.ENCRYPT_ACTIVE) {
        (0, _database.deleteDb)();
      }
      if (currentUser && status === _discourse.ENCRYPT_ACTIVE && (!currentUser.encrypt_private || Object.keys(JSON.parse(currentUser.encrypt_private)).length === 0)) {
        (0, _pluginApi.withPluginApi)("0.11.3", api => {
          let basePath = (0, _getUrl.default)("/").replace(/\/$/, "");
          const warning = _I18n.default.t("encrypt.no_backup_warn", {
            basePath
          });
          api.addGlobalNotice((0, _template.htmlSafe)(warning), "key-backup-notice", {
            level: "warn",
            dismissable: true,
            dismissDuration: moment.duration(1, "day")
          });
        });
      }
      const messageBus = container.lookup("service:message-bus");
      if (messageBus && status !== _discourse.ENCRYPT_DISABLED) {
        messageBus.subscribe("/plugin/encrypt/keys", function (data) {
          currentUser.setProperties({
            encrypt_public: data.public,
            encrypt_private: data.private
          });
          appEvents.trigger("encrypt:status-changed", true);
        });
      }
    }
  };
});