define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-uploads", ["exports", "discourse/lib/plugin-api", "discourse/lib/uploads", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/uppy-upload-encrypt-plugin", "pretty-text/allow-lister"], function (_exports, _pluginApi, _uploads, _discourse, _uppyUploadEncryptPlugin, _allowLister) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "encrypt-uploads",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const siteSettings = container.lookup("service:site-settings");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        _allowLister.DEFAULT_LIST.push("a[data-key]");
        _allowLister.DEFAULT_LIST.push("a[data-type]");
        _allowLister.DEFAULT_LIST.push("img[data-key]");
        _allowLister.DEFAULT_LIST.push("img[data-type]");
        const uploads = {};
        api.addComposerUploadPreProcessor(_uppyUploadEncryptPlugin.default, _ref => {
          let {
            composerModel
          } = _ref;
          return {
            composerModel,
            siteSettings,
            storeEncryptedUpload: (fileName, data) => {
              uploads[fileName] = data;
            }
          };
        });
        api.addComposerUploadMarkdownResolver(upload => {
          const encryptedUpload = uploads[upload.original_filename.replace(/\.encrypted$/, "")] || Object.values(uploads).find(u => u.filesize === upload.filesize);
          if (!encryptedUpload) {
            return;
          }
          Object.assign(upload, encryptedUpload.metadata);
          const markdown = (0, _uploads.getUploadMarkdown)(upload).replace("](", `|type=${encryptedUpload.type}|key=${encryptedUpload.key}](`);
          delete uploads[encryptedUpload.original_filename];
          return markdown;
        });
      });
    }
  };
});