define("discourse/plugins/discourse-encrypt/lib/database", ["exports", "discourse-common/config/environment", "discourse/plugins/discourse-encrypt/lib/protocol", "rsvp", "@ember/test"], function (_exports, _environment, _protocol, _rsvp, _test) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DB_VERSION = _exports.DB_NAME = void 0;
  _exports.deleteDb = deleteDb;
  _exports.indexedDb = void 0;
  _exports.loadDbIdentity = loadDbIdentity;
  _exports.saveDbIdentity = saveDbIdentity;
  _exports.setIndexedDb = setIndexedDb;
  _exports.setUseLocalStorage = setUseLocalStorage;
  _exports.setUserAgent = setUserAgent;
  _exports.userAgent = _exports.useLocalStorage = void 0;
  /**
   * Used to determine the waiter state
   *
   * @var {Number}
   */
  let pendingOperationsCount = 0;
  if ((0, _environment.isTesting)()) {
    (0, _test.registerWaiter)(() => pendingOperationsCount === 0);
  }

  /**
   * @var {String} DB_NAME Name of IndexedDb used for storing key pairs
   */
  const DB_NAME = _exports.DB_NAME = "discourse-encrypt";

  /**
   * @var {String} DB_VERSION Version of IndexedDb schema user for storing key pairs
   */
  const DB_VERSION = _exports.DB_VERSION = "discourse-encrypt-version";

  /**
   * When truthy, it uses local storage instead of IndexedDb to store user
   * identities.
   *
   * @var {Boolean}
   */
  let useLocalStorage = _exports.useLocalStorage = false;

  /**
   * Force usage of local storage instead of IndexedDb.
   *
   * @param {Boolean} value Whether to use local storage.
   */
  function setUseLocalStorage(value) {
    if (!(0, _environment.isTesting)()) {
      throw new Error("`setUseLocalStorage` can be called from tests only");
    }
    _exports.useLocalStorage = useLocalStorage = value;
  }

  /**
   * IndexedDb API used to store CryptoKey objects securely
   */
  let indexedDb = _exports.indexedDb = window.indexedDB;

  /**
   * Sets IndexedDb backend
   *
   * @param {Object} value
   */
  function setIndexedDb(value) {
    if (!(0, _environment.isTesting)()) {
      throw new Error("`setIndexedDb` can be called from tests only");
    }
    _exports.indexedDb = indexedDb = value;
  }

  /**
   * Browser's user agent string
   */
  let userAgent = _exports.userAgent = window.navigator.userAgent;

  /**
   * Sets browser's user agent string
   *
   * @param {String} value
   */
  function setUserAgent(value) {
    if (!(0, _environment.isTesting)()) {
      throw new Error("`setUserAgent` can be called from tests only");
    }
    _exports.userAgent = userAgent = value;
  }

  /**
   * Warm up IndexedDB to ensure it works normally
   *
   * Used in Safari 14 to work around a bug. indexedDB.open hangs in
   * Safari if used immediately after page was loaded:
   * https://bugs.webkit.org/show_bug.cgi?id=226547
   *
   * @return {Promise<void>}
   */
  function initIndexedDb() {
    if (!userAgent.match(/Version\/14.+?Safari/)) {
      return _rsvp.Promise.resolve();
    }
    let interval;
    return new _rsvp.Promise((resolve, reject) => {
      const tryIndexedDb = () => indexedDb.databases().then(resolve, reject);
      interval = setInterval(tryIndexedDb, 100);
      tryIndexedDb();
    }).finally(() => {
      clearInterval(interval);
    });
  }

  /**
   * Opens plugin's Indexed DB.
   *
   * @param {Boolean} create Whether to create database if missing.
   *
   * @return {IDBOpenDBRequest}
   */
  function openDb(create) {
    const req = indexedDb.open(DB_NAME, 1);
    req.onupgradeneeded = evt => {
      if (!create) {
        evt.target.transaction.abort();
        return;
      }
      const db = evt.target.result;
      if (!db.objectStoreNames.contains("keys")) {
        db.createObjectStore("keys", {
          keyPath: "id",
          autoIncrement: true
        });
      }
    };
    return req;
  }
  function saveIdentityToLocalStorage(identity) {
    return (0, _protocol.exportIdentity)(identity).then(exported => {
      window.localStorage.setItem(DB_NAME, exported.private);
      window.localStorage.setItem(DB_VERSION, identity.version);
    });
  }

  /**
   * Save a key pair to plugin's Indexed DB.
   *
   * @param {Object} identity
   *
   * @return {Promise}
   */
  function saveDbIdentity(identity) {
    if (useLocalStorage) {
      return saveIdentityToLocalStorage(identity);
    }
    pendingOperationsCount++;
    return new _rsvp.Promise((resolve, reject) => {
      const req = openDb(true);
      req.onerror = () => {
        saveIdentityToLocalStorage(identity).then(resolve, reject);
      };
      req.onsuccess = evt => {
        const db = evt.target.result;
        const tx = db.transaction("keys", "readwrite");
        const st = tx.objectStore("keys");
        const dataReq = st.add(identity);
        dataReq.onsuccess = dataEvt => {
          window.localStorage.setItem(DB_NAME, true);
          window.localStorage.setItem(DB_VERSION, identity.version);
          db.close();
          resolve(dataEvt);
        };
        dataReq.onerror = () => {
          saveIdentityToLocalStorage(identity).then(resolve, reject);
        };
      };
    }).finally(() => {
      pendingOperationsCount--;
    });
  }
  function loadIdentityFromLocalStorage() {
    const exported = window.localStorage.getItem(DB_NAME);
    return exported && exported !== "true" ? (0, _protocol.importIdentity)(exported) : _rsvp.Promise.reject();
  }

  /**
   * Gets the last stored key-pair from plugin's IndexedDB.
   *
   * @return {Promise<Object>} A tuple consisting of public and private key.
   */
  function loadDbIdentity() {
    if (useLocalStorage) {
      return loadIdentityFromLocalStorage();
    }
    pendingOperationsCount++;
    return initIndexedDb().then(() => {
      return new _rsvp.Promise((resolve, reject) => {
        const req = openDb(false);
        req.onerror = () => {
          loadIdentityFromLocalStorage().then(resolve, reject);
        };
        req.onsuccess = evt => {
          const db = evt.target.result;
          const tx = db.transaction("keys", "readonly");
          const st = tx.objectStore("keys");
          const dataReq = st.getAll();
          dataReq.onsuccess = dataEvt => {
            const identities = dataEvt.target.result;
            db.close();
            if (identities && identities.length > 0) {
              const identity = identities[identities.length - 1];
              resolve(identity);
            } else {
              reject();
            }
          };
          dataReq.onerror = () => {
            loadIdentityFromLocalStorage().then(resolve, reject);
          };
        };
      });
    }).finally(() => {
      pendingOperationsCount--;
    });
  }

  /**
   * Deletes plugin's IndexedDB and all user keys.
   *
   * @return {Promise}
   */
  function deleteDb() {
    window.localStorage.removeItem(DB_NAME);
    window.localStorage.removeItem(DB_VERSION);
    pendingOperationsCount++;
    return initIndexedDb().then(() => {
      return new _rsvp.Promise(resolve => {
        const req = indexedDb.deleteDatabase(DB_NAME);
        req.onsuccess = evt => resolve(evt);
        req.onerror = evt => resolve(evt);
        req.onblocked = evt => resolve(evt);
      });
    }).finally(() => {
      pendingOperationsCount--;
    });
  }
});