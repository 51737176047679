define("discourse/plugins/discourse-encrypt/discourse/initializers/decrypt-post-revision", ["exports", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "rsvp"], function (_exports, _pluginApi, _text, _discourse, _protocol, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "decrypt-post-revisions",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        api.modifyClassStatic("model:post", {
          pluginId: "decrypt-post-revisions",
          loadRevision() {
            return this._super(...arguments).then(result => {
              if (!(0, _discourse.hasTopicKey)(result.topic_id)) {
                return result;
              }
              const topicKey = (0, _discourse.getTopicKey)(result.topic_id);
              return _rsvp.Promise.all([topicKey.then(k => (0, _protocol.decrypt)(k, result.raws.previous)), topicKey.then(k => (0, _protocol.decrypt)(k, result.raws.current))]).then(_ref => {
                let [previous, current] = _ref;
                return _rsvp.Promise.all([previous.raw, (0, _text.cook)(previous.raw), current.raw, (0, _text.cook)(current.raw)]);
              }).then(_ref2 => {
                let [prevRaw, prevCooked, currRaw, currCooked] = _ref2;
                result.body_changes.side_by_side = `
                <div class="revision-content">${prevCooked}</div>
                <div class="revision-content">${currCooked}</div>`;
                result.body_changes.side_by_side_markdown = `
                <table class="markdown">
                  <tr>
                    <td class="diff-del">${prevRaw}</td>
                    <td class="diff-ins">${currRaw}</td>
                  </tr>
                </table>`;
                return result;
              });
            });
          }
        });
      });
    }
  };
});